<template>
  <div id="conoce">
      <header>
          <h1 class="main-title text-left">{{$tc('conoce.title',1)}} <br> {{$tc('conoce.title',2)}}</h1>
      </header>
      <b-container fluid>
          <b-row class="py-5">
              <b-col>
                <h2 class="text-pink">
                    {{$tc('conoce.subtitle')}}
                </h2>
              </b-col>
          </b-row>


          <b-row class="pb-5">
              <b-col cols="12" lg="6" class="p-1 pl-lg-5 pr-lg-4">
                  <div class="h-100 d-flex flex-column justify-content-between">
                      <div class="daily-card pb-4">
                          <div class="daily-card-header bgk-pink text-white py-2 pl-3 pl-lg-5 text-left">
                              <h5 class="p-0 m-0">{{$tc('conoce.card1.title')}}</h5>
                          </div>
                          <div class="daily-card-body bgk-blue text-white d-flex pl-3 pl-lg-5 py-3">
                              <p class="w-75 text-left h6">
                                  {{$tc('conoce.card1.body')}}
                              </p>
                          </div>
                      </div>

                      <div class="daily-card pb-4">
                          <div class="daily-card-header bgk-pink text-white py-2 pl-3 pl-lg-5 text-left">
                              <h5 class="p-0 m-0">{{$tc('conoce.card2.title')}}</h5>
                          </div>
                          <div class="daily-card-body bgk-blue text-white pl-3 pl-lg-5 py-3 pr-1">
                              <p class="text-left">
                                  {{$tc('conoce.card2.body')}}
                              </p>
                          </div>
                      </div>

                      <div class="daily-card pb-4 pb-lg-0">
                          <div class="daily-card-header bgk-pink text-white py-2 pl-3 pl-lg-5 text-left">
                              <h5 class="p-0 m-0">{{$tc('conoce.card3.title')}}</h5>
                          </div>
                          <div class="daily-card-body bgk-blue text-white pl-3 pl-lg-5 py-3 pr-1">
                              <p class="text-left">
                                  {{$tc('conoce.card3.body')}}
                              </p>
                          </div>
                      </div>
                  </div>
              </b-col>
              <b-col cols="12" lg="6" class="p-0 d-flex align-items-center">
                  <img class="img-fluid d-lg-none" src="../assets/desde_adentro/foto1.jpg" alt="">
                  <div class="w-100 h-100 d-none d-lg-block" :style="`background-image: url(${require('../assets/desde_adentro/foto1.jpg')});background-size: cover;`"></div>
              </b-col>
          </b-row>

          <b-row>
              <b-col class="p-0 d-flex align-items-center order-2 order-lg-1" cols="12" lg="6">
                  <img class="img-fluid d-lg-none" src="../assets/desde_adentro/foto2.jpg" alt="">
                  <div class="w-100 h-100 d-none d-lg-block" :style="`background-image: url(${require('../assets/desde_adentro/foto2.jpg')});background-size: cover;`"></div>
              </b-col>
              <b-col class="p-1 pr-lg-5 pl-lg-4 order-1 order-lg-2" cols="12" lg="6">
                  <div class="h-100 d-flex flex-column justify-content-between">
                      <div class="daily-card pb-4">
                          <div class="daily-card-header bgk-pink text-white py-2 pl-3 pl-lg-5 text-left">
                              <h5 class="p-0 m-0">{{$tc('conoce.card4.title')}}</h5>
                          </div>
                          <div class="daily-card-body bgk-blue text-white d-flex pl-3 pl-lg-5 py-3">
                              <p class="w-75 text-left h6">
                                  {{$tc('conoce.card4.body')}}
                              </p>
                          </div>
                      </div>

                      <div class="daily-card pb-4">
                          <div class="daily-card-header bgk-black text-green py-2 pl-3 pl-lg-5 text-left">
                              <h5 class="p-0 m-0">{{$tc('conoce.card5.title')}}</h5>
                          </div>
                      </div>

                      <div class="daily-card pb-4">
                          <div class="daily-card-header bgk-green text-blue py-2 pl-3 pl-lg-5 text-left">
                              <h5 class="p-0 m-0">{{$tc('conoce.card6.title')}}</h5>
                          </div>
                          <div class="daily-card-body bgk-pink text-white d-flex pl-3 pl-lg-5 py-3 pr-1">
                              <p class="w-75 text-left h6">
                                  {{$tc('conoce.card6.body')}}
                              </p>
                          </div>
                      </div>

                      <div class="daily-card pb-4">
                          <div class="daily-card-header bgk-green text-blue py-2 pl-3 pl-lg-5 text-left">
                              <h5 class="p-0 m-0">{{$tc('conoce.card7.title')}}</h5>
                          </div>
                          <div class="daily-card-body bgk-pink text-white d-flex pl-3 pl-lg-5 py-3 pr-1">
                              <p class="text-left h6">
                                  {{$tc('conoce.card7.body',1)}}
                                  <br>
                                  <small>{{$tc('conoce.card7.body',2)}}</small>
                              </p>
                          </div>
                      </div>

                      <div class="daily-card pb-4 pb-lg-0">
                          <div class="daily-card-header bgk-green text-blue py-2 pl-3 pl-lg-5 text-left">
                              <h5 class="p-0 m-0">{{$tc('conoce.card8.title')}}</h5>
                          </div>
                          <div class="daily-card-body bgk-pink text-white d-flex pl-3 pl-lg-5 py-3 pr-1">
                              <p class="text-left h6">
                                  {{$tc('conoce.card8.body')}}
                              </p>
                          </div>
                      </div>
                  </div>
              </b-col>
          </b-row>
      </b-container>
      
      <!-- <div class="banner-conoce">
          <h2 v-html="$t('conoce.banner1')"></h2>
      </div> -->
      <!-- <div class="camino">
          <p class="mb-5 px-5" v-html="$t('conoce.p1')"></p>

          <b-container>
              <b-row>
                  <b-col cols="6"  lg="12" md="12" sm="6" xm="6" class="camino-drawing my-3">
                      <div id="line"></div>
                      <b-row class="h-100 align-items-center">
                          <b-col lg="3" md="3" cols="12" class="item">
                              <div class="icon-wrapper">
                                  <div class="icon">
                                        <img src="../assets/ilustraciones/iconos-01.svg" alt="aprende">
                                    </div>
                              </div>
                              
                          </b-col>
                          <b-col lg="3" md="3" cols="12" class="item">
                              <div class="icon-wrapper">
                                  <div class="icon">
                                        <img src="../assets/ilustraciones/iconos-02.svg" alt="aprende">
                                    </div>
                              </div>
                              
                          </b-col>
                          <b-col lg="3" md="3" cols="12" class="item">
                              <div class="icon-wrapper">
                                  <div class="icon">
                                        <img src="../assets/ilustraciones/iconos-03.svg" alt="aprende">
                                    </div>
                              </div>
                              
                          </b-col>
                          <b-col lg="3" md="3" cols="12" class="item">
                              <div class="icon-wrapper">
                                  <div class="icon">
                                        <img src="../assets/ilustraciones/iconos-04.svg" alt="aprende">
                                    </div>
                              </div>
                              
                          </b-col>
                      </b-row>
                  </b-col>
                  <b-col cols="6" lg="12" md="12" sm="6" xs="6" class="camino-desc my-3">
                      <b-row>
                          <b-col lg="3" md="3" cols="12" class="item">
                              <h3>{{$t('conoce.icon1.title')}}</h3>
                              <p>{{$t('conoce.icon1.desc')}}</p>
                          </b-col>
                          <b-col lg="3" md="3" cols="12" class="item">
                              <h3>{{$t('conoce.icon2.title')}}</h3>
                              <p>{{$t('conoce.icon2.desc')}}</p>
                          </b-col>
                          <b-col lg="3" md="3" cols="12" class="item">
                              <h3>{{$t('conoce.icon3.title')}}</h3>
                              <p>{{$t('conoce.icon3.desc')}}</p>
                          </b-col>
                          <b-col lg="3" md="3" cols="12" class="item">
                              <h3>{{$t('conoce.icon4.title')}}</h3>
                              <p>{{$t('conoce.icon4.desc')}}</p>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>
          </b-container>
      </div> -->
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
    name: 'ConoceMindhub',
    methods:{
        ...mapMutations(['styleNav'])
    },
    beforeMount(){
        this.styleNav({type:'dark',variant: 'blue', logo:'logo_blanco.webp'})
    }
}
</script>

<style scoped>

header {
  background-image: url("../assets/desde_adentro/header.jpg");
  background-size: cover;
  padding: 15% 0 15% 5%;
  color: #baff43;
}

@media screen and (max-width: 425px){
  header{
    margin-top: 0;
  }

  h2{
    font-size: 1.2rem;
  }
}

.daily-card-body p{
    font-size: .85rem;
    margin: 0;
}

.daily-card-hours p{
    font-size: 1.3rem;
}

    .banner-conoce{
        background-color: #0000ff;
        color: #baff43;
        margin: 5% 0;
        padding: 5%;
        
    }

    .banner-conoce h2{
        font-weight: bolder;
    }

    @media screen and (max-width: 576px) {
        .banner-conoce h2{
            font-size: 1.5rem;
        }
    }

    @media screen and (max-width: 576px) {
        .banner-conoce h2{
            font-size: 1.3rem;
        }
    }

    .camino h3{
        font-weight: bolder;
    }

    .camino .item{
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .camino .icon-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .camino .icon{
        width: 75px;
        border: 1px solid #0000ff;
        border-radius: 10px;
        background: #fff;
    }

    .camino .icon img{
        width: 75px;
    }

    .camino-drawing{
        position: relative;
    }

    #line{
        width: 100%;
        height: 1px;
        background-color: #0000ff;
        position: absolute;
        left: 0;
        top: 50%;
    }

    @media screen and (max-width: 767px){
        #line{
            width: 1px;
            height: 100%;
            top: 0;
            left: 50%;
        }

        .camino-desc h3{
            font-size: 1.3em;
        }

        .camino-desc p{
            font-size: .9em;
        }
    }

</style>